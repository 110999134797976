<template>
  <div class="icon_box_all">
    <ul class="icon_box">
      <li class="li1"></li>
      <li class="li2"></li>
      <li class="li3"></li>
    </ul>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    //popupVisible: {
    //type: Boolean,
    //default: false
    //}
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.icon_box_all {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_box {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 26px;
  height: 18px;
  gap: 5px;
  justify-content: space-around;
  padding: 4px;
  box-sizing: border-box;
  align-items: baseline;
  // border-radius: 0 0 0 1px;
  border-radius: 4px;
  li {
    width: 3px;
    background-color: #4ff1c9;
    display: flex;
    flex-shrink: 0;
    // border-radius: 2px 2px 0 0;
  }
  .li1 {
    height: 40%;
  }
  .li2 {
    height: 90%;
  }
  .li3 {
    height: 70%;
  }
}
</style>
